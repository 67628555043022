import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@css/index.less";
import "@css/iconfont.css";

import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import Toastr from "toastr";
Toastr.options = {
  positionClass: "toast-top-center",
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "1500",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
Vue.prototype.$toastr = Toastr;

// 禁用生产环境提示
Vue.config.productionTip = false;

// Element挂载到Vue
Vue.$message = Element.Message;
Vue.use(Element);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
