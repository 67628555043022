import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    // 默认路由
    {
      path: "/",
      name: "首页",
      component: (resolve) => require(["@/views/home"], resolve),
    },
    {
      path: "/docai",
      name: "docai",
      component: (resolve) => require(["@/views/tool/pdf-tool"], resolve),
    },
    {
      path: "/openai",
      name: "openai",
      component: (resolve) => require(["@/views/openai"], resolve),
    },
    {
      path: "/login",
      name: "login",
      component: (resolve) => require(["@/views/login"], resolve),
    },
    {
      path: "/aboutus",
      name: "aboutus",
      component: (resolve) => require(["@/views/aboutus"], resolve),
    },
    {
      path: "/jztdemo",
      name: "jztdemo",
      component: (resolve) => require(["@/views/jztdemo"], resolve),
    },
    {
      path: "*",
      component: (resolve) => require(["@/views/404"], resolve),
    },
  ],
});

// 路由跳转前
router.beforeEach((to, from, next) => {
  // 可用于拦截导航并执行一些操作，例如验证用户身份、权限控制等。
  next();
});

// 路由跳转后
router.afterEach((to, from) => {
  window.scrollTo(0, 0); // 每次路由改变滚动条都回到顶部
});

export default router;
