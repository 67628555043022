export default {
  state: {
    user: null, // 用户信息
    token: localStorage.getItem("access-token") || "", // 初始化 token
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("access-token", token); // 保存 token 到本地存储
    },
    LOGOUT(state) {
      state.user = null;
      state.token = "";
      localStorage.removeItem("access-token"); // 移除本地存储中的 token
    },
  },
  actions: {
    login({ commit }, { user, token }) {
      commit("SET_USER", user);
      commit("SET_TOKEN", token);
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
  },
};
